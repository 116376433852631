/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });

try {
  window.$ = window.jQuery = require('jquery');
} catch (e) {
}

require('./scripts/form');
require('./scripts/tab');
require('./scripts/sliders');
require('slick-carousel');

// ONLY LIST PRODUCTS
if ($('#products').length) {
  // require('./scripts/category-list');
  require('./scripts/products-list');
}

// ONLY LIST PRODUCTS
// if ($('#history').length) {
//   require('./scripts/history');
// }

// ONLY VIEW CONTACT
if ($('#contact').length) {
  require('./scripts/form-validate');
  require('./scripts/contact-form');
}

$(function () {
  let win = $(window);
  let html = $('html,body');
  let header = $('#header');
  let navLinkScroll = header.find('.nav-link-scroll');
  let scrollBanner = $('.ico-scroll');
  let produtcs = $('#products');
  let backToTop = $('.btn-back-top');
  let overlay = $('.overlay');
  let icoMenu = header.find('.ico-menu-mobile');
  let menu = header.find('.menu');
  let btnBack = $('#product-detail .product-name').find('.back');
  let hash = location.hash;
  let secVideo = $('#video');
  let optionVol = secVideo.find('.options');

  let blog = $('.space');
  let shortDescription = blog.find('.item .description .data');

  // responsive
  let media800 = window.matchMedia('(max-width: 800px)');


  scrollBanner.on('click', () => {

    let pTop = produtcs.offset().top - header.height();

    html.animate({
      scrollTop: pTop
    });

  });

  backToTop.on('click', () => {
    html.animate({
      scrollTop: 0
    });
  });

  function scrollMenu(e) {
    e.preventDefault();

    let el = $(this);
    var ref = el.attr('href');

    var hh = header.height();
    var elTop = $(ref).offset().top;

    // console.log(hh);
    // console.log(Math.round(elTop));

    var id = elTop - hh;

    navLinkScroll.removeClass('active');
    el.addClass('active');

    html.animate({
      scrollTop: Math.round(id)
    });

    if (el.parents('.menu').hasClass('active-menu')) {
      header.removeClass('active-menu');
      menu.removeClass('active-menu');
      $('#wrapper').removeClass('active-menu');
      overlay.fadeOut();
    }

  }

  navLinkScroll.on('click', scrollMenu);

  if (media800.matches) {

    secVideo.find('.overlay').addClass('active');

    secVideo.on('click', '.ico-play', () => {
      $('video')[0].play();
      $('video').prop('muted', false);
      $('video').prop('controls', true);
      secVideo.find('.overlay').fadeOut();
      secVideo.find('.options').removeClass('active');
    })
  }

  function scrollPage(e) {

    win.scroll(() => {
      let scroll = $(this).scrollTop();
      let nameMenu = ['banner', 'products', 'history', 'contact'];

      // let bottomProduct = $(window).height() - $('#products').offset().top - $('#products').height();
      let bottomProduct = $('#products').height() + 30;

      let topHistory = $('#history').offset().top;

      nameMenu.forEach((data, index) => {

        let id = $('#' + data);
        let offsetID = id.offset().top;
        let sectionTop = Math.round(offsetID) - header.height();

        if (scroll >= sectionTop) {
          header.find('.nav-link').removeClass('active');
          header.find('.nav-link-' + data).addClass('active');
        }

      });

      if (media800.matches) {
        if (scroll >= bottomProduct) {

          // setTimeout(() => {
          //   $('video')[0].play();
          //   $('video').prop('muted', false);
          // }, 100);

        }
      }

    });
  }

  if ($('#banner').length) {
    scrollPage();
  }

  icoMenu.on('click', function (e) {
    overlay.fadeIn();
    header.addClass('active-menu');
    menu.addClass('active-menu');
    $('#wrapper').addClass('active-menu');
  });


  overlay.on('click', function (e) {
    let el = $(e.target);

    if (el.is(overlay)) {
      header.removeClass('active-menu');
      menu.removeClass('active-menu');
      $('#wrapper').removeClass('active-menu');
      overlay.fadeOut();
    }
  });

  if ($('.ico-share')) {
    $('.ico-share').on('click', function (e) {
      e.preventDefault();
      $(this).next().toggleClass('d-flex')
    })
  }

  $('.galleries').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // dots: true
    arrows: true
  });

  if ($('#space-content')) {
    $('.dropdown > .dropdown-menu > .dropdown-item').on('click', function () {
      $(this).parent().parent().find('input[name="category"]').val($(this).data('value'));
      $(this).parent().parent().find('button').html($(this).data('value').toUpperCase());
      formSubmit();
    });

    $('#form-blog').on('submit', function (e) {
      e.preventDefault();
      formSubmit();
    });
  }

  function formSubmit() {
    let data = $('#form-blog :input').filter(function (i, v) {return $(v).val() !== '';}).serialize();
    data === '' ? window.location.href = '/blog': window.location.href = '?' + data + '&page=1';
  }

  btnBack.on('click', (e) => {
    e.preventDefault();
    history.back();
  });

  if ( hash.split('#')[1] == 'products' ) {
    html.animate({
      scrollTop: $(hash).offset().top - header.height()
    });
  }

  function volume(e) {
    let el = $(this);
    let video = $('video')[0];

    // let muted = true;
    // muted = !muted;
    // video.prop('muted', muted);

    if (el.hasClass('active')) {
      el.removeClass('active');
      $('video').prop('muted', false);
    } else {
      el.addClass('active');
      $('video').prop('muted', true);
    }
  }

  optionVol.on('click', volume);

  function createEllipsis(containerId) {
    let container = containerId;
    var containerHeight = container.height();
    var text = container.find('p');

    console.log(containerHeight);
    console.log(text.outerHeight());

    while (text.outerHeight() > containerHeight) {

      console.log(container);
      text.text(function (index, text) {
        return text.replace(/\W*\s(\S)*$/, '...');
      });
    }
  }

  function dataEllipsis(e) {

    for (let index = 0; index < shortDescription.length; index++) {
      const element = shortDescription[index];
      let text = $(element).find('p');

      console.log($(element).find('p').outerHeight());
      console.log($(element).height());

      if ( text.outerHeight()  > $(element).height() ) {
        console.log(index, element);
        // $(element).find('p').text('active');
        // text.text('active');
        text.text(function (index, text) {
          console.log(index, text);
          return text.replace(/\W*\s(\S)*$/, '...');
        });
      }

    }
  }

  // dataEllipsis();

  // createEllipsis(shortDescription);


  // // LOAD IMAGE FAKE BLOG

  // var imagePort = `
  //   <a href="https://the-barberia.whiz.pe/blog/detail/post-1-barberia">
  //     <div class="img" style="background-image: url('/images/detail/fake/portada.png')"></div>
  //   </a>
  // `;

  // $('#space-content .box-item .item').prepend(imagePort);
  // $('.box-featured-article .item').prepend(imagePort);
});
