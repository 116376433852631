$(function() {
  let apiContact = '/api/contact-form';

  let boxFormList = $('.form-contact');
  let labelName = boxFormList.find('label[for="name"]');
  let labelLastName = boxFormList.find('label[for="last_name"]');
  let labelEmail = boxFormList.find('label[for="email"]');
  let labelCellphone = boxFormList.find('label[for="cellphone"]');
  let labelMessage = boxFormList.find('label[for="message"]');

  $.ajax({
    url: apiContact,
    method: 'GET',
    dataType: 'json',
    success: function (response) {

      let data = response.data[0];

      labelName.text(data.name);
      labelLastName.text(data.last_name);
      labelEmail.text(data.email);
      labelCellphone.text(data.cellphone);
      // labelMessage.text(data.message);

      if ( data.message != 1 ) {
        labelMessage.parent().parent().remove();
      }

    }
  });

});
