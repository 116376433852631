// window.jQuery = $ = require('jquery');

$(function() {

  let btnTab = $('.btn-tab');
  let tabs = $('.tab');

  function tab(e){

     e.preventDefault();

    let el = $(this);

    let path = el.attr('href').split('#')[1];

    $('.btn-tab').removeClass('active');

    el.addClass('active');

    tabs.filter('.tab').hide().removeClass('show');
    tabs.filter('#' + path).fadeIn().addClass('show');

    if ( $('#products').length ) {

      let anchor = el.data('anchor');
      let url = `${location.origin}/#${anchor}`;

      location.replace(url);

      $('html,body').animate({
        scrollTop: el.offset().top - $('#header').height()
      });

    }

  }

  btnTab.on('click', tab);
  $('#products').on('click','.btn-tab', tab);

});
