require('jquery-validation');

$(function() {

  let contactSection = $('#contact');
  let formContact = $('.form-contact');
  let apiContact = '/api/contact';

  let boxThanks = contactSection.find('.box-form-thanks');
  let boxForm = contactSection.find('.box-form');

  $(".iptText").keydown(function (e) {
    var a = e.key;
    // if (a.length == 1) return /[a-z]|\$|#|\*/i.test(a);
    if (a.length == 1) return /^[a-zA-Z\s ñáéíóúÑÁÉÍÓÚ]+$/i.test(a);
    return true;
  });

  $(".iptNumber").keydown(function (e) {
    var a = e.key;
    if (a.length == 1) return /[0-9]|\+|-/.test(a);
    return true;
  });

  $.validator.addMethod("emailonly", function (value, element) {
    return this.optional(element) || /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/i.test(value);
  }, "Ingrese email valido");

  formContact.validate({
    errorPlacement: function errorPlacement(error, element) {
      element.before(error);
    },
    rules: {
      // password: {
      //   required: true
      // },
      email: {
        required: true,
        email: true,
        emailonly: true
      }
    },

    highlight: function (element) {
      var el = $(element);
      el.parent().addClass('error');


    },
    unhighlight: function (element) {
      var el = $(element);
      el.parent().removeClass('error');

    },
    submitHandler: function (form) {

      var myForm = formContact.serialize();

      $.ajax({
        url: apiContact,
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: myForm,

        beforeSend: function (response) {

        },
        success: function (response) {
          console.log(response);
          console.log('ok');

          boxForm.hide();
          boxThanks.fadeIn();
        },

        error: function (response, rpta, created) {
          console.log(response);
          console.log('error');
        }
      });

    },
    errorPlacement: function (error, element) {}

  });


});
