$(function () {
  let apiProductList = '/api/category/';

  let boxProductsList = $('.box-products-list');

  function loadDataProduct(param) {

    let el = $(this);
    let href = el.attr('href');
    let div = $(href);
    let divContent;
    // let id = el.data('id');
    let id;

    let hash = location.hash;

    // if (param == 0) {
    //   id = 1;
    //   divContent = $('.tab-content-box').filter(':visible').find('.box-products-list');
    // } else {
    //   id = el.data('id');
    //   divContent = div.find('.box-products-list');
    // }

    id = 1;
    divContent = $('.tab-content-box').filter(':visible').find('.box-products-list');

    if (hash) {
      let nameHash = hash.split('#')[1];

      $('.btn-tab').removeClass('active');
      $('#btn-' + nameHash).addClass('active');

      let idHash = $('#btn-' + nameHash).data('id');

      id = idHash;
    }

    if ( param != 0 ) {
      id = el.data('id');
      divContent = div.find('.box-products-list');
    }

    if ( typeof id == 'undefined' ) {
      id = 1;
      divContent = $('.tab-content-box').filter(':visible').find('.box-products-list');

      $('.box-nav-category').find('.col:first-child .btn-tab').addClass('active');
    }

    $.ajax({
      url: `${apiProductList}${id}`,
      method: 'GET',
      dataType: 'json',
      // beforeSend: function (response) {
      //   console.log('...');
      // },
      success: function (response) {

        $('.box-products-list').empty();

        $.each(response[0].subcategories, (i, value) => {

          let itemProduct = '';

          $.each(value.products, (i, value) => {

            let imgCover;

            $.each(value.galleries, (i, value) => {
              if (value.type == 'product-header') {
                imgCover = value.path;
              }

            });

            if ( imgCover == undefined ) {
              imgCover = '/images/img-random.png';
            }

            itemProduct += `
              <div class="card-item">
                  <div class="item">
                    <div class="item-box">
                      <a href="/product/${value.slug}">
                          <picture>
                            <img src="${imgCover}">
                          </picture>
                      </a>
                      <a class="data" href="/product/${value.slug}">
                        <h4>${value.name}</h4>
                      </a>
                    </div>
                    <div class="item-border"></div>
                  </div>
              </div>
            `;

          });

          let item = `
            <div class="box-sub-categorie">
              <h2>${value.name}</h2>
              <div class="row box-item">
                ${itemProduct}
              </div>
            </div>
          `;

          let divItem = $(item);
          divContent.append(divItem);
        });

      },
      error: function (response) {
        console.log(response);
        console.log('error');
      }
    });
  }

  loadDataProduct(0);

  $('.box-nav-category').on('click', '.btn-tab', loadDataProduct);

  function loadStyling(e) {}

  function loadCabello(e) {}

  function loadRostro(e) {}

});
