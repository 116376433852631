$(function() {

  // formulario
  $('.ui-field input, .ui-field textarea')
  .bind("input load change paste keyup", function () {
    let el = $(this);

    if (el.val()) {
      el.closest('.ui-field').addClass('active');

    } else {
      el.closest('.ui-field').removeClass('active');
    }
  })
  .on('focus', function (e) {
    let el = $(this);
    el.closest('.ui-field').addClass('focus');
  })
  .on('blur', function (e) {
    let el = $(this);
    el.closest('.ui-field').removeClass('focus');
  });

});
