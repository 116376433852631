// window.jQuery = $ = require('jquery');
require('slick-carousel');

$(function() {

  var productDetail = $('#product-detail');
  var sliderImage = productDetail.find('.slider-image');
  var sliderDots = productDetail.find('.slider-dots');

  sliderImage.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: sliderDots,
    responsive: [
      {
        breakpoint: 801,
        settings: {
          dots: true
        }
      }
    ]
  });

  sliderDots.slick({
    infinite: false,
    slidesToShow: 3,
    // slidesToScroll: 1,
    asNavFor: sliderImage,
    arrows: true,
    dots: false,
    // centerMode: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          arrows: false
        }
      }
    ]
  });

});
